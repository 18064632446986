import Cookies from 'js-cookie';

export interface CookieConsentCookie {
    acceptAll: boolean;
}

export const getCookie = (cookieName: string): CookieConsentCookie | null => {
    try {
        return JSON.parse(Cookies.get(cookieName) || 'null');
    } catch (e) {
        console.error(e);
        return null;
    }
};
