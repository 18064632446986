import { getCookie } from '3-components/CookieConsent/CookieConsentUtils';
/* eslint-disable object-shorthand */

// Reference Consent Mode: https://support.google.com/analytics/answer/9976101?hl=en
// Manage Consent: https://developers.google.com/tag-platform/security/guides/consent

// ON BED WILL DETERMINE THE defaultConsent value by country from cloudflare
// window.defaultConsent = 'granted' | 'denied'


// Define dataLayer and the gtag function.
window.dataLayer = window.dataLayer || [];
window.defaultConsent = window.defaultConsent || 'denied';
window.consentCookieName = window.consentCookieName || 'consent';

window.IFMSCR = {
    gtag: function() {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments);
    },
    setAllConsent: function(value) {
        return {
            ad_storage: value,
            analytics_storage: value,
            personalization_storage: value,
            functionality_storage: value,
            security_storage: value,
        };
    },
};

const cookieData = getCookie(window.consentCookieName);

// initial based on cookie
if (!cookieData) {
    window.IFMSCR.gtag('consent', 'default', window.IFMSCR.setAllConsent(window.defaultConsent));
} else if (cookieData.acceptAll) {
    window.IFMSCR.gtag('consent', 'default', window.IFMSCR.setAllConsent('granted'));
} else {
    window.IFMSCR.gtag('consent', 'default', window.IFMSCR.setAllConsent('denied'));
}
